import React from 'react';

import styles from './LinkListModule.css';
import { Link, Media } from '../../../models/Strapi';
import { UrlService } from '../../../services/UrlService';

type LinkList = {
    links: Link[];
};

type LiksListModuleProps = {
    title: string;
    background?: Media;
    backgroundFallback?: string;
    moduleScreenId?: string;
    component: [LinkList];
};

const defaultColor = '#0b103d';

const getTargetAction = (action: string) => {
    switch (action) {
        case 'newTab':
            return '_blank';
        case 'currentTab':
            return '_self';
        default:
            return '_blank';
    }
};

const LinkListModule = React.memo(({ component: [linkList], background, backgroundFallback }: LiksListModuleProps) => {
    const backGroundColor = backgroundFallback || defaultColor;

    const getBackground = (background, backgroundFallback) => {
        const result = {} as React.CSSProperties;
        if (background) {
            result.backgroundImage = `url(${UrlService.createStrapiMediaUrl(background.url)})`;
        }
        result.backgroundColor = backgroundFallback;
        return result;
    };

    return (
        <section className={styles.container} style={getBackground(background, backGroundColor)}>
            <div className={styles.linkList}>
                {linkList.links.map((link, index) => (
                    <span className={styles.linkListItem} key={link.url}>
                        <a style={{ color: link.color }} href={link.url} target={getTargetAction(link.action)}>
                            {link.label}
                        </a>
                    </span>
                ))}
            </div>
        </section>
    );
});

export default LinkListModule;
